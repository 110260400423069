import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ver si implemento esto: https://stackoverflow.com/questions/37949981/call-child-method-from-parent


export default React.forwardRef(({titleProp,descriptionProp,handleClose}, ref) =>  {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(titleProp||"");
  const [description, setDescription] = React.useState(descriptionProp||"");

  React.useImperativeHandle(ref, () => ({

    alerta(tit,desc) {
    	setTitle(tit);
    	setDescription(desc);
    	setOpen(true);
    }

  }));


  function runHandleClose(){
  	setOpen(false);
  	return handleClose && handleClose();
  }

  return (
      <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={runHandleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={runHandleClose}>ok</Button>
      </DialogActions>
    </Dialog>
  );
});
