const axios = require('axios').default; //axios: api https://axios-http.com/docs/req_config
const env = require("./enviroment");
const AppData = require("./AppData");


let Connector = {};

Connector.getHeaders = (endpoint) => {
	//si no está vencido paso token de auth
	if (endpoint != '/oauth/login'){
		const user = AppData.getLoggedUser();

		return user?{ authorization: user.TK }:{};
	}

	return {};
}


Connector.checkSession = (err) =>{
	if (err?.response?.status == 401){
		AppData.logout();
	}
}
Connector.showLoader = () =>{
	try{
		document.getElementsByClassName("main-loader")[0].style.display="block";
	}catch(Err){}
}

Connector.hideLoader = () =>{
	try{
		document.getElementsByClassName("main-loader")[0].style.display="none";
	}catch(Err){}
}


Connector.get = async (endpoint, params, success, error, loader = false) => {
	loader && Connector.showLoader();
	await axios.get(env.urlbe+endpoint,
		 {	
			headers: Connector.getHeaders(endpoint),
		    params: params
		  }

	   ).then(res => {
	   		loader && Connector.hideLoader();
	    	return success && success(res);
      }).catch(err => {
      		Connector.checkSession(err);
      		loader && Connector.hideLoader();
	    	return error && error(err);
      });

}


Connector.post = async (endpoint, body, success, error, loader = false, params = null) => {
	loader && Connector.showLoader();
	await axios.post(env.urlbe+endpoint,
		 body,
		 {	
			headers: Connector.getHeaders(endpoint),
		    params: params,
		 }
	   ).then(res => {
	   		loader && Connector.hideLoader();
	    	return success && success(res);
      }).catch(err => {
      		Connector.checkSession(err);
      		loader && Connector.hideLoader();
	    	return error && error(err);
      });

}



Connector.put = async (endpoint, body, success, error, loader = false, params = null) => {
	loader && Connector.showLoader();
	await axios.put(env.urlbe+endpoint,
		 body,
		 {	
			headers: Connector.getHeaders(endpoint),
		    params: params,
		 }
	   ).then(res => {
	   		loader && Connector.hideLoader();
	    	return success && success(res);
      }).catch(err => {
      		Connector.checkSession(err);
      		loader && Connector.hideLoader();
	    	return error && error(err);
      });

}


Connector.delete = async (endpoint, params, success, error, loader = false) => {
	loader && Connector.showLoader();
	await axios.delete(env.urlbe+endpoint,
		 {	
			headers: Connector.getHeaders(endpoint),
		    params: params
		  }

	   ).then(res => {
	   		loader && Connector.hideLoader();
	    	return success && success(res);
      }).catch(err => {
      		Connector.checkSession(err);
      		loader && Connector.hideLoader();
	    	return error && error(err);
      });

}





export default Connector;