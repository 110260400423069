import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const ButtonNext = ({
  width, 
  height, 
  name,
  onClick
}) => {
  return (
    <Stack spacing={2} direction="row">
      <Button 
      onClick={()=>{onClick && onClick()}}
      variant="outlined"
      style={{
        borderRadius:13, 
        borderColor:'#E21119',
        height: height ? height : 43, 
        width: width ? width: 174, 
        borderWidth:2, solid: '#E21119', 
        fontSize: 14, color: '#000000'
        }}>
          {name? name:'Boton'}
      </Button>
    </Stack>
  );
}

export default ButtonNext;
