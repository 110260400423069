import * as React from 'react';
import { Card, FormControl, InputLabel, Stack, TextField,Autocomplete,FormHelperText} from "@mui/material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import '@fontsource/roboto/400.css';
import ButtonNext from "../buttonNext/ButtonNext";
import { Container, width } from "@mui/system";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import BasicSelectFilled from '../Selects/BasicSelectFilled/BasicSelectFilled';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Connector from '../../settings/Connector';
import SmartAlert from '../alert/SmartAlert';
import Checkbox from '@mui/material/Checkbox';
import moment from 'moment';
import AppData from '../../settings/AppData';

const isLetters = (str) => /^[A-Za-z\s]*$/.test(str);

export default function InputData ({grupo}) {

  const [invalidGroup, setInvalidGroup] = React.useState(grupo ? true : false);

  const [date, setDate] = React.useState(null);
  const [aceptaTerminos, setAceptaTerminos] = React.useState(null);
  const [requiereTarjeta, setRequiereTarjeta] = React.useState(false);

  const [provincia, setProvincia] = React.useState(null)
  const [localidadEnable, setLocalidadEnable] = React.useState(null)
  const [listProvinces, setListProvinces] = React.useState([]);
  const [ciudad, setCiudad] = React.useState(null)
  const [listCiudades, setListCiudades] = React.useState([]);
  const [listSucursales, setListSucursales] = React.useState([]);

  const [step, setStep] = React.useState("DATOS");
  const [seEnvioEmail, setSeEnvioEmail] = React.useState(true);
  const [urlTyC, setUrlTyC] = React.useState("https://www.diarco.com.ar/terminos-y-condiciones");
  

  const steps = {
    datos:"DATOS",
    fin:"FIN"
  }

  const [cliente, setCliente] = 
    React.useState(
      {
        DNI:null,
        Apellido:"",
        Nombre:"",
        Genero:null,
        FechaNacimiento:null,
        AreaCelular:null,
        Celular:null,
        Provincia:null,
        ProvinciaNombre:null,
        Ciudad:null,
        CiudadNombre:null,
        Sucursal:null,
        Email:null,
        Grupo:null,
        Tarjeta:null,
      }
    );

   const [error, setError] = 
    React.useState(
      {
        DNI:false,
        Apellido:false,
        Nombre:false,
        Genero:false,
        FechaNacimiento:false,
        AreaCelular:false,
        Celular:false,
        Provincia:false,
        ProvinciaNombre:false,
        Ciudad:false,
        CiudadNombre:false,
        Sucursal:false,
        Email:false,
        Grupo:false,
        Tarjeta:false,
      }
    );

    const [touch, setTouch] = 
    React.useState(
      {
        DNI:false,
        Apellido:false,
        Nombre:false,
        Genero:false,
        FechaNacimiento:false,
        AreaCelular:false,
        Celular:false,
        Provincia:false,
        ProvinciaNombre:false,
        Ciudad:false,
        CiudadNombre:false,
        Sucursal:false,
        Email:false,
        Grupo:false,
        Tarjeta:false,
      }
    );

    const [help, setHelp] = 
    React.useState(
      {
        DNI:'',
        Apellido:'',
        Nombre:'',
        AreaCelular:'',
        Genero:'',
        FechaNacimiento:'',
        Celular:'',
        Provincia:'',
        ProvinciaNombre:'',
        Ciudad:'',
        CiudadNombre:'',
        Sucursal:'',
        Email:'',
        Grupo:'',
        Tarjeta:'',
      }
    );

  const [grupoEmail, setGrupoEmail] = React.useState('')

  const alertRef = React.useRef();


  const handleDateChange = (newDate) => {
    console.log(newDate);
    console.log(moment(newDate).format('DD/MM/YYYY'));
    setDate(newDate);
    changeDef("FechaNacimiento",moment(newDate).format('DD/MM/YYYY'));
    onBlur("FechaNacimiento");
  };
  const handleProvChange = ({target}) =>{
    setProvincia(target.value)
    changeDef("Provincia",target.value);
    const element = getItemFromId(target.value);
    changeDef("ProvinciaNombre",element.value);

    changeDef("Ciudad","");
    changeDef("CiudadNombre","");
    setCiudad(null);

    return (element)
  };
  const handleLocalChange = (value) =>{
    try{
      changeDef("Ciudad",value.id);
      changeDef("CiudadNombre",value.label);
      setCiudad(value);
    }catch(err){
      changeDef("Ciudad","");
      changeDef("CiudadNombre","");
      setCiudad(null);
    }
    onBlur("Ciudad");
    onBlur("CiudadNombre");
  };
  const handleSucursalChange = (value) =>{
    try{
      changeDef("Sucursal",value.id);
    }catch(err){
      changeDef("Sucursal","");
    }
    onBlur("Sucursal");
  };
  const getItemFromId = (id) => {
    for ( const element of listProvinces ){
      if (element.id == id ) { 
        return element
      }
    }
  };

  const endpointBranch = '/public/sucursales/autocomplete';
  const endpointProvinces = '/public/provincias';
  const endpointCiudades = '/public/ciudades';
  const endpointClientes = '/public/clientes';
  const endpointVerifyGrupo = '/public/grupos/verify';
  const endpointConfigs = '/public/configs';

  React.useEffect(() => {
      Connector.get(
        endpointVerifyGrupo,
        {HashUrl: grupo},
        (response) => {
          if(response.data.status){
            setRequiereTarjeta(response.data.Requiere);
            setInvalidGroup(false);
            setCliente({...cliente, Grupo : response.data.Codigo}); 
            setGrupoEmail(response.data.Email)
          }else{
            alertRef.current.alerta("Ups!", `Algo salio mal, intente nuevamente. Si el error presiste contactanos a  ${grupoEmail || 'info@diarco.com.ar'}`);
          }    
        },
        (error) => {
          console.log(error)
          alertRef.current.alerta("Ups!", `Algo salio mal, intente nuevamente. Si el error presiste contactanos a  ${grupoEmail || 'info@diarco.com.ar'}`);
        },
        true
      );
  }, []);

  React.useEffect(() => {
    getProvinces();    
  }, []);
  
  React.useEffect(() => {
    getSucursales();
  }, []);
  React.useEffect(() => {
    getConfig();
  }, []);

  const getProvinces = () => {
    Connector.get(
      endpointProvinces,
      {},
      (response) => {
        console.log(response);
        const listTransformed = response.data.recordset.map(item => {
          return {id: item.ID, value: item.Nombre};
        });
        setListProvinces(listTransformed)
        console.log(listTransformed)
      },
      (error) => {
        console.error(error);
        alertRef.current.alerta("Ups!", `Algo salio mal, intente nuevamente. Si el error presiste contactanos a  ${grupoEmail || 'info@diarco.com.ar'}`);
      },
      true
    );
  }

  const getConfig = () => {
    Connector.get(
      endpointConfigs,
      {},
      (response) => {
        setUrlTyC(response.data.result.urlTyC)
      },
      (error) => {
        console.error(error);
        alertRef.current.alerta("Ups!", `Algo salio mal, intente nuevamente. Si el error presiste contactanos a  ${grupoEmail || 'info@diarco.com.ar'}`);
      },
      true
    );
  }

  React.useEffect (() => {
    if (provincia){
      Connector.get(
        endpointCiudades,
        {IDProvincia:provincia},
        (response) => {
          console.log(response);
          const listTransformed = response.data.recordset.map(item =>{
            return {id: item.ID, label: item.Nombre};
          });
          setListCiudades(listTransformed)
          console.log(listTransformed)
          setLocalidadEnable(true);

        },
        (error) => {
          console.error(error)
          alertRef.current.alerta("Ups!", `Algo salio mal, intente nuevamente. Si el error presiste contactanos a  ${grupoEmail || 'info@diarco.com.ar'}`);
        },
        true
      );
    }
  },[provincia])

  const getSucursales = () => {
    Connector.get(
      endpointBranch,
      {},
      (response) => {
        console.log(":ASDSADSAD", response);
        const listTransformed = response.data.result.recordset.map(item => {
          return {id: item.Codigo, label: item.Nombre};
        });
        setListSucursales(listTransformed)
        console.log(listTransformed)
      },
      (error) => {
        console.error(error)
        alertRef.current.alerta("Ups!", `Algo salio mal, intente nuevamente. Si el error presiste contactanos a  ${grupoEmail || 'info@diarco.com.ar'}`);
      },
      true
    );
  }

  const changeDNI = (val) => {
    cliente.DNI = val.replace(/[^0-9]/ig,"");
    setCliente({...cliente}); 
    setTouch({...touch, DNI:true});
  }

  const changeDef = (name, val) => {
    cliente[name] = val;
    setCliente({...cliente});
    touch[name] = true; 
    setTouch({...touch});
  }


  const changeDefSoloTexto = (name, val) => {
    if (isLetters(val)) {
      cliente[name] = val;
      setCliente({...cliente});
      touch[name] = true; 
      setTouch({...touch});
    }
  }

  // const alphaTextChange = (e, name, val) => {
  //   const { value } = e.target;
  //   if (isLetters(value)) {
  //     cliente[name] = val;
  //     setCliente({...cliente});
  //     touch[name] = true; 
  //     setTouch({...touch});
  //   }
  // };


  const changeDefSoloNum = (name, val) => {
    cliente[name] = val.replace(/[^0-9]/ig,"");
    setCliente({...cliente});
    touch[name] = true; 
    setTouch({...touch});
  }


  const onBlur = (name) => {
     if (touch[name]){
       validateField(name);
     }
  }

  const validateField = (name) => {
    if (name == "DNI"){
      if (!(cliente[name] && (cliente[name]+"").length>=7)){
        help[name] = "Complete un "+name+" válido";
        error[name] = true;
      }else{
        help[name] = "";
        error[name] = false;
      }
    }else if (name == "Email"){
      if (!(cliente[name] && ValidateEmail(cliente[name]+""))){ 
        help[name] = "Complete un "+name+" válido";
        error[name] = true;
      }else{
        help[name] = "";
        error[name] = false;
      }
    }else if (name == "Tarjeta"){
      if (requiereTarjeta && (!(cliente[name]) || cliente[name].length != 13)){
        help[name] = "Complete una "+name+" válida";
        error[name] = true;
      }else{
        help[name] = "";
        error[name] = false;
      }
    }else if (name == "FechaNacimiento"){
      const edad = getEdad(cliente[name])

      console.log(edad);
      if (!(cliente[name] && (cliente[name]+"").length>=7 && edad>=18 )){
        help[name] = "Fecha no válida";
        if (!isNaN(edad) && edad<18 && edad>=0)
        alertRef?.current?.alerta("Ups!","Para registrarte en Diarco Club es necesario que seas mayor de 18 años")
        error[name] = true;

        if (help["Genero"] == ""){
          help["Genero"] = " "
        }

      }else{
        help[name] = "";
        error[name] = false;

        if (help["Genero"] == " "){
          help["Genero"] = ""
        }
      }
    }else if (name == "Genero"){
      if (!(cliente[name] && (cliente[name]+"").length>=1)){
        help[name] = "Seleccione opción";
        error[name] = true;

        if (help["FechaNacimiento"] == ""){
          help["FechaNacimiento"] = " "
        }

      }else{
        help[name] = "";
        error[name] = false;

        if (help["FechaNacimiento"] == " "){
          help["FechaNacimiento"] = ""
        }
      }
    }else{
      if (!(cliente[name] && (cliente[name]+"").length>=1)){
        if (name == "Ciudad"){
          help[name] = "Complete una localidad válida";
        }else if (name == "Sucursal"){
          help[name] = "Complete una sucursal válida";
        }else if (name == "Provincia"){
              help[name] = "Complete una provincia válida";
        }else{
          help[name] = "Complete un "+name+" válido";
        }
        error[name] = true;
      }else{
        help[name] = "";
        error[name] = false;
      }

    }
    setHelp({...help});
    setError({...error});
  }

  
  function ValidateEmail(val) {
    var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (val.match(validRegex)) {
      return true;
    } else {
      return false;
    }

  }



  function getEdad(dateString) {
    let hoy = new Date()
    let fechaNacimiento = new Date((dateString||"").split("/").reverse().join("-")+"T00:00:00");
    if (fechaNacimiento.getFullYear()<1900){
      return -1;
    }
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--
    }
    return edad
  }

  const handleAceptaTerminos = (event) => {
    setAceptaTerminos(event.target.checked);
  };

  function validarTodosAlta(){
    touch.DNI = true;
    touch.Apellido = true;
    touch.Nombre = true;
    touch.Genero = true;
    touch.FechaNacimiento = true;
    touch.Provincia = true;
    touch.ProvinciaNombre = true;
    touch.Ciudad = true;
    touch.CiudadNombre = true;
    touch.Sucursal = true;
    touch.Email = true;
    touch.Tarjeta = true;
    setTouch(touch);


    validateField("DNI");
    validateField("Apellido");
    validateField("Nombre");
    validateField("Genero");
    validateField("FechaNacimiento");
    validateField("Provincia");
    validateField("ProvinciaNombre");
    validateField("Ciudad");
    validateField("CiudadNombre");
    validateField("Sucursal");
    validateField("Email");
    validateField("Tarjeta");



    return  !error.DNI &&
            !error.Apellido &&
            !error.Nombre &&
            !error.Genero &&
            !error.FechaNacimiento &&
            !error.Provincia &&
            !error.ProvinciaNombre &&
            !error.Ciudad &&
            !error.CiudadNombre &&
            !error.Sucursal &&
            !error.Email &&
            !error.Tarjeta;
  }

  function crearCliente(cliente){
    return new Promise((resolve, reject)=> {
      Connector.post(
        endpointClientes,
        cliente,
        (response) => {
          console.log('response',response)
          resolve(response)
        },
        (error) => {
          console.error('error',error); resolve(
            error?.response || 
            "ERROR")
        ;},
        true
      )
    })
  }

  async function finalizar(){

    window.grecaptcha.enterprise.execute('6LdasYwiAAAAAOXoNNjlDEIBgXeWLVEOQ5S7bHfw', {action: 'login'}).then(function(token) {
      console.log('token: ')
      console.log(token)
    

      if (!validarTodosAlta()){
        alertRef.current.alerta("","Por favor, completá todos los campos obligatorios para finalizar el registro.");
      }else if (aceptaTerminos){
        cliente.token = token
        crearCliente(cliente).then(response => {
          switch (response?.data?.validacion) {
            case 'TARJETA-YA-REGISTRADA':
                alertRef.current.alerta("Ups!", `La tarjeta ingresada ya se encuentra registrada. Si tiene consultas, contactanos a  ${grupoEmail || 'info@diarco.com.ar'}`);
                break;
            case "CLIENTE-REGISTRADO-VERIFICADO":
                alertRef.current.alerta( "Ups!",`¡Ya estás registrado!`);
              break;
            case "CLIENTE-REGISTRADO-PENDIENTE":
                alertRef.current.alerta( "Ups!",`¡Ya estás registrado! Te enviamos un correo de confirmación para activar los beneficios. Revisá también en correo no deseado. En caso de no recibirlo dentro de las 24hs contactanos a ${grupoEmail}`);
              break;
            case "EMAIL-REGISTRADO-VERIFICADO":
                alertRef.current.alerta("Ups!","¡El Email está registrado!");
              break;
            case "EMAIL-REGISTRADO-PENDIENTE":
                alertRef.current.alerta("Ups!",`¡El Email está registrado! Te enviamos un correo de confirmación para activar los beneficios. Revisá también en correo no deseado. En caso de no recibirlo dentro de las 24hs contactanos a ${grupoEmail}`);
              break;
            case "DNI-REGISTRADO-VERIFICADO":
                alertRef.current.alerta("Ups!",`¡El DNI está registrado!`);
              break;  
            case "DNI-REGISTRADO-PENDIENTE":
                alertRef.current.alerta("Ups!",`¡El DNI está registrado! Te enviamos un correo de confirmación para activar los beneficios. Revisá también en correo no deseado. En caso de no recibirlo dentro de las 24hs contactanos a ${grupoEmail}`);
              break;    
            case "NO-VALIDADO":
                alertRef.current.alerta("Ups!",`Detectamos que el nombre, apellido, fecha de nacimiento o el DNI ingresados no se corresponden. Por favor revisá esos datos. Si crees que es un error contactanos a ${grupoEmail}`);
              break;  
            case "OK":
                setStep(steps.fin);
              break;       
            case "REPLACE":
                setStep(steps.fin);
              break; 
            case "NUEVA-TARJETA":
                setStep(steps.fin);
                setSeEnvioEmail(false);
              break;      
            default:
                alertRef.current.alerta("Ups!", `Algo salio mal, intente nuevamente. Si el error presiste contactanos a  ${grupoEmail || 'info@diarco.com.ar'}`);
              break;
          }
        }).catch((err)=>{
          alertRef.current.alerta("Ups!", `Algo salio mal, intente nuevamente. Si el error presiste contactanos a  ${grupoEmail || 'info@diarco.com.ar'}`);
        })
      }else{
        alertRef.current.alerta("Ups!","Debes aceptar los términos y condiciones para avanzar.");
      }

    });
  }

  return(    
      !invalidGroup ? <div className="datos-card-cont">        
      {step == steps.datos?
      <Card className="datos-card">
        <p className='titulo'>
          Ingresá tus datos:
        </p>
        <p className='subTitulo'>
          Complete sus datos personales para <br></br> disfrutar de los beneficios:
        </p>
        
        <TextField inputProps={{maxLength: 100}} value={cliente.Nombre} onBlur={()=>{onBlur("Nombre")}} onChange={event => changeDefSoloTexto("Nombre",event.target.value)} 
          error={error.Nombre} helperText={help.Nombre} id="filled-basic" label="Nombre" fullWidth variant="filled" margin="none" type="text"/>
        <TextField inputProps={{maxLength: 100}} value={cliente.Apellido} onBlur={()=>{onBlur("Apellido")}} onChange={event => changeDefSoloTexto("Apellido",event.target.value)} error={error.Apellido} helperText={help.Apellido} 
          id="filled-basic" label="Apellido" fullWidth variant="filled" margin="none" />
        <TextField error={error.DNI} helperText={help.DNI} 
        onBlur={()=>{onBlur("DNI")}} 
        value={cliente.DNI}
        inputProps={{maxLength: 8}} onChange={event => changeDNI(event.target.value)} id="filled-basic" label="DNI" fullWidth variant="filled" margin="none" />
          
        <div className="fechaGeneroCont">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
            label="Fecha de Nacimiento"
            inputFormat="dd/MM/yyyy"
            value={date}
            onChange={handleDateChange}
            renderInput={(params) => 
              
              <TextField 
                {...params} 
                sx={{ }} 
                variant="filled" 
                error={error.FechaNacimiento} helperText={help.FechaNacimiento}  
                onBlur={()=>{onBlur("FechaNacimiento")}} onChange={event => changeDef("FechaNacimiento",event.target.value)} 
              />
              }
            />
          </LocalizationProvider>
              
            
          <FormControl >
            <InputLabel id={'sexo'} variant="filled"> Género </InputLabel>
            <Select
              labelId={'sexo'}
              id={'sexo'}
              // value={item}
              // onChange={handleChange}
              label={'Genero'}
              variant="filled"
              error={error.Genero} helperText={help.Genero}  
              onBlur={()=>{onBlur("Genero")}} onChange={event => changeDef("Genero",event.target.value)} 
            >
                <MenuItem key={'F'} value={"F"}> Femenino</MenuItem>
                <MenuItem key={'M'} value={"M"}> Masculino</MenuItem>
                <MenuItem key={'O'} value={"O"}> Otro</MenuItem>
                <MenuItem key={'X'} value={"X"}> Prefiero no decirlo</MenuItem>
            </Select>
            {help.Genero?<FormHelperText>{help.Genero}</FormHelperText>:null}
          </FormControl>
        </div>
          
          
        <TextField error={error.Email} helperText={help.Email} id="filled-basic" label="E-mail" fullWidth variant="filled" margin="none" 
                    inputProps={{maxLength: 100}} onBlur={()=>{onBlur("Email")}} onChange={event => changeDef("Email",event.target.value)} />
          
        <div style={{margin: "10px 7% auto 7%"}}>Teléfono móvil (opcional)</div>
        <div className="telefonoCont">
              <FormControl >
                <TextField helperText="ej: 011" inputProps={{maxLength: 5}} value={cliente.AreaCelular} onChange={event => changeDefSoloNum("AreaCelular",event.target.value)}   label={"Cód.Área"} variant="filled" fullWidth />
              </FormControl>
              <FormControl >
                <TextField helperText="Solo números, sin cód.área y sin 15" inputProps={{maxLength: 8}} value={cliente.Celular} onChange={event => changeDefSoloNum("Celular",event.target.value)}  label={"Número"} variant="filled" fullWidth />
              </FormControl>

        </div>  
          
        <FormControl >
          <InputLabel id={'provincia'} variant="filled"> Provincia de Residencia </InputLabel>
          <Select
              error={error.Provincia} helperText={help.Provincia}  
              labelId={'provincia'}
              id={'provincia'}
              value={provincia}
              label={'Provincia'}
              variant="filled"
              onChange={handleProvChange}
              onBlur={()=>{onBlur("Provincia")}} 

            >
                { listProvinces.map((prov)=>(
                <MenuItem key={prov.id} value={prov.id}>
                {prov.value}
                </MenuItem>
                ))}
              
            </Select>
          {help.Provincia?<FormHelperText>{help.Provincia}</FormHelperText>:null}

          {/* <BasicSelectFilled items={listProvinces}  nombre={'Provincia'} handleClick={item => setProvincia(item.id)} value={provincia}  /> */}
        </FormControl>
        
          
        <Autocomplete
          disablePortal
          freeSolo
          variant="filled"
          id="ciudad"
          options={listCiudades}
          disabled={!localidadEnable}
          onChange={(event, newValue) => {
              handleLocalChange(newValue)
          }}
          onBlur={()=>{onBlur("Ciudad")}} 
          value={ciudad} 
          renderInput={(params) => <TextField {...params} error={error.Ciudad} helperText={help.Ciudad}  variant="filled" label="Localidad de Residencia" inputProps={{ ...params.inputProps, autoComplete: 'new-password',  }}  />}
        />


        <Autocomplete
          disablePortal
          freeSolo
          variant="filled"
          id="sucursales"
          options={listSucursales}
          
          onChange={(event, newValue) => {
              handleSucursalChange(newValue)
          }}
          onBlur={()=>{onBlur("Sucursal")}} 
          renderInput={(params) => <TextField {...params} error={error.Sucursal} helperText={help.Sucursal}  variant="filled" label="Sucursal Preferida" inputProps={{ ...params.inputProps, autoComplete: 'new-password',  }} />}
        />

        {
          requiereTarjeta && (
            <TextField
              error={error.Tarjeta} 
              helperText={help.Tarjeta}  
              id="filled-basic"
              inputProps={{maxLength: 13}} 
              onChange={event => {
                changeDefSoloNum("Tarjeta",event.target.value);
              }}  
              label="Número de Tarjeta"
              variant="filled" 
              margin="none" 
              fullWidth 
              value={cliente.Tarjeta} 
              />
          )
        }

        <div className="acepto-cont">
            <Checkbox checked={aceptaTerminos} onChange={handleAceptaTerminos} /> Acepto los  <span className="bold pointer" onClick={()=>{window.open(urlTyC,"_new")}}>términos y condiciones</span>
        </div>
        <br/>
        <div className="recaptacha"></div>
        <div className="btt-container" >
          <ButtonNext name={'Finalizar'} onClick={() => finalizar()} />
        </div>
        
      </Card>:null}
      
      
      {step === steps.fin?
      <Card className="datos-card">
        <p className='titulo'>
        ¡Ya estás registrado!
        </p>
        <p className='subTitulo'>
          Listo ya podes disfrutar de los beneficios de <br />
          <span className='diarcoClub'> Diarco Club</span>
        </p>
        {seEnvioEmail ? 
        <p className='treintaDias'>
        Te enviamos un correo de confirmación para activar los beneficios.
        Revisá también en correo no deseado.<br /><br />
        En caso de no recibirlo dentro de las 24hs contactanos a <span className="bold">{grupoEmail}</span>
        </p>
        :null }
      </Card>:null
      }
      <h6 style={{color:'gray', textAlign:'center'}} >Versión: {AppData.version}</h6>
      <SmartAlert ref={alertRef} />
      </div>
      :<SmartAlert ref={alertRef} />
  );
}