
const AppData = {};

AppData.setLoggedUser = (usr) =>{
	localStorage.setItem("lu",JSON.stringify(usr));
}

AppData.getLoggedUser = () =>{
	if (localStorage.getItem("lu") && localStorage.getItem("lu") != null && localStorage.getItem("lu")!="" && localStorage.getItem("lu")!= "null" && localStorage.getItem("lu")!='undefined')
		return JSON.parse(localStorage.getItem("lu"));
	else 
		return null
}

AppData.logout = () => {
	localStorage.setItem("lu",null);
	window.location.href = "/";
}

AppData.version= '0.0.8';

module.exports = AppData;