import '@fontsource/roboto/400.css';
import InputData from "./components/InputsDatos/InputDatosPersonales";
import { useParams } from 'react-router-dom';
export default function MainBox ({match}) {
  const { grupo } = useParams();

  return (
    <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor:'#b3b0b0',
      padding:"40px 0 "
    }}>
      <InputData grupo={grupo ? grupo : ''}/>      

      <div className="main-loader">
        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  );
}
  
  